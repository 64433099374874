import React from "react"

import { Container } from 'reactstrap' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-equipment' 
import worldIcon from '../../assets/images/world.png' 
import crossImg from '../../assets/images/point-cross.png' 
import redValue from '../../assets/images/red-star.png' 
import oea from '../../assets/images/oea.png' 
import CTPAT from '../../assets/images/CTPAT.png' 
 
export default () => (
  <LayoutEsp>
    <SEO title="Equipment" />
    <Slider title="EQUIPO" />
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>
	<div className="equipment-background" >
		<div className="truck-equipment-background" >
		</div>
		<Container className="pt-4 container_height">
			<div className="row rowEquipment"> 
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{'121'}</h4> 
						<p className="blueBlockP">{'CAMIONES'}</p>  
						<hr/>  
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{'350'}</h4> 
						<p className="blueBlockP">{'DRY VANS DE 54"'}</p>    
						<hr/> 
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{'43'}</h4> 
						<p className="blueBlockP">{'PLATAFORMAS DE 48"'}</p>  
						<hr/>   
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<div className="blueBlockDiv">  
							<h4 className="blueBlockH">{''}</h4>  
							<p className="blueBlockP">{'PANELES'}</p>    
							<hr/> 
						</div> 
					</div> 
			    </div> 
			</div>
		</Container>
	</div>  
	<div className="equipment-background md-none" >
		<div className="truck-equipment-background-2" >
		</div>
		<Container className="pt-4 container_height">
			<div className="row rowEquipment"> 
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{''}</h4> 
						<p className="blueBlockP2">{'24" BOB TAILS TRUCK'}</p>  
						<hr/>  
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{''}</h4> 
						<p className="blueBlockP2">{'STEP DECK'}</p>    
						<hr/> 
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{''}</h4> 
						<p className="blueBlockP2">{'REFEERS'}</p>  
						<hr/>   
					</div> 
			    </div> 
			</div>
		</Container>
	</div>  
	<div className="cross-equipment-background" > 
		<img className="crossImg" src={crossImg} alt="..."/>
		<Container className="pt-4"> 
			<div className="row">
			    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<img className="redValue" src={redValue} alt="red icon mission"/> 
					<h4 className="equipmentH">BENEFICIOS DE <b>NUESTRO EQUIPO</b></h4> 
					<p className="equipmentP">{'Excelencia'}</p>  
			    </div> 
				<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 equipmentGray">
					<img className="CTPAT" width={240} src={CTPAT} alt="..."/>
					<img className="oea" src={oea} alt="..."/>
					<h4 className="grayH_2">
					</h4>
					<p className="grayText">{'Equipos en excelentes condiciones de limpieza y mantenimiento exhaustivo según normativa CTPAT y OEA.'}</p>
				</div>			  
			</div>
		</Container>
	</div>   
  </LayoutEsp>
)
